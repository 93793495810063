@use "../../styles/assets" as *;

nav {
  padding: 2rem;
  display: flex;
  justify-content: space-between;
}

.logo {
  text-transform: capitalize;
  font-size: 1.5rem;
  color: $primary;
  font-weight: 500;
}

.navlinks {
  display: flex;
  justify-content: space-between;
  gap: 1rem;
}

.navlinks li a {
  color: $primary;
  font-weight: 500;
  text-transform: capitalize;
  border-radius: 0.5rem;
  padding: 0.25rem 0.5rem;

  &:hover,
  &:focus {
    background: $background;
    box-shadow: 5px 5px 10px #b5b5b5, -5px -5px 10px #ffffff;
  }
}

.btn {
  background: $background;
  box-shadow: 5px 5px 10px #b5b5b5, -5px -5px 10px #ffffff;
  padding: 0.75rem 1.5rem;
  border-radius: 0.5rem;
  color: $primary;
  font-weight: 500;
  width: fit-content;
}

.togglebtn {
  background: $background;
  box-shadow: 5px 5px 10px #b5b5b5, -5px -5px 10px #ffffff;
  font-size: 1.5rem;
  padding: 0.2rem 0.4rem;
  border-radius: 0.5rem;
  color: $primary;
  display: none;
}

@media screen and (max-width:800px) {
  .togglebtn {
    display: flex;
  }

  .logo {
    font-size: 1rem;
  }

  .togglebtn[aria-expanded="false"] + .navlinks {
    opacity: 0;
    transform: translateX(-200vw);
  }

  .navlinks {
    transform: translateX(0);
    opacity: 1;
    flex-direction: column;
    position: absolute;
    top: 5rem;
    left: 1rem;
    right: 1rem;
    height: 80vh;
    background: $background;
    box-shadow: 5px 5px 10px #b5b5b5, -5px -5px 10px #ffffff;
    font-size: 1rem;
    justify-content: center;
    align-items: center;
    z-index: 1;
  }
}
