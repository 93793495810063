@use "../../styles/assets" as *;

.socials {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;
  min-width: 100vw;
  text-transform: capitalize;
  margin: 10rem 0;
}

.socialbx {
  width: 50rem;
  padding: 4rem 2rem;
  border-radius: 0.5rem;
  border: 0.5rem solid $background;
  box-shadow: 5px 5px 10px #b5b5b5, -5px -5px 10px #ffffff;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  background: url("../assets/social2.jpg") no-repeat #ffff;
  background-position: 150% 0%;
  background-size: 80%;

  h3 {
    color: $darkblack;
    font-size: 1.5rem;
    font-weight: 700;
  }
  p {
    color: $darkblack;
    font-weight: 400;
    font-size: 0.8rem;
    max-width: 20rem;
    margin-bottom: 1.5rem;
  }
  button {
    background: $yellow;
    box-shadow: 5px 5px 10px #c38c09, -5px -5px 10px #ffbe0c;
    padding: 0.75rem 1.5rem;
    border-radius: 0.5rem;
    color: $darkblack;
    text-transform: capitalize;
    font-weight: 500;
    width: fit-content;
  }
}

.icon_bx {
  display: flex;
  gap: 1rem;
}

.icon {
  width: 2rem;
  height: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background: #e0e0e0;
  box-shadow: 5px 5px 10px #b5b5b5, -5px -5px 10px #ffffff;

  img {
    width: 1.5rem;
    height: 1.5rem;
  }
}

@media screen and (max-width: 500px) {
  .socials {
    height: fit-content;
  }

  .socialbx {
    width: 18rem;
    margin: 1rem;
    margin: 1rem;
    background-position: 0% -527%;
    background-size: 150%;

    h3 {
      font-size: 1.25rem;
    }
  }
}
